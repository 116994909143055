type Config = {
	basename: string
	defaultPath: string
	fontFamily: string
	borderRadius: number
	API_BASE_URL: string
	LOG_IN_API: string
	SIGN_UP_API: string
	TIME_ZONES_API: string
	OTTOLEARN_API_BASE_URL: string
}

const developmentConfig = {
	API_BASE_URL: 'http://localhost:3000/api',
}

const productionConfig = {
	API_BASE_URL:
		process.env.REACT_APP_API_URL || 'https://api.dev.soteria120.com/api',
}

const config: Config = {
	basename: '/',
	defaultPath: '/dashboard',
	fontFamily: `'Manrope', sans-serif`,
	borderRadius: 12,
	OTTOLEARN_API_BASE_URL: 'https://api.ottolearn.io/v1/rest',
	...(process.env.NODE_ENV === 'production'
		? productionConfig
		: developmentConfig),
} as Config

config.LOG_IN_API = `${config.API_BASE_URL}/users/signin`
config.SIGN_UP_API = `${config.API_BASE_URL}/users/signup`
config.TIME_ZONES_API = `${config.API_BASE_URL}/timezones`

export const USER_INFO = JSON.parse(localStorage.getItem('userData') || '{}')
export const SESSION_TOKEN = localStorage.getItem('sessionToken')

export const EMAIL = localStorage.getItem('email')
export const TOKEN = localStorage.getItem('token')

/* eslint-disable prefer-destructuring */
export const OTTOLEARN_API_BASE_URL = config.OTTOLEARN_API_BASE_URL
/* eslint-enable prefer-destructuring */

export const BASE_URL = config.API_BASE_URL

// export const BASE_URL_DEV = 'http://localhost:3000'

export const companies = [
	{
		value: '1',
		label: 'Soteria120',
	},
]

export const companyName = [
	{
		value: 'SOTERIA120',
		label: 'SOTERIA120',
	},
]

export const programmes = [
	{
		value: 'Bluespark Operator Competency',
		label: 'Bluespark Operator Competency',
	},
]

export default config
