import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'
import { Divider, List, Typography } from '@material-ui/core'
import NavItem from '../NavItem'
import NavCollapse from '../NavCollapse'
import { MenuItem } from '../types'

// Define the styles
const useStyles = makeStyles((theme: Theme) => ({
	menuCaption: {
		...theme.typography.menuCaption,
	},
	subMenuCaption: {
		...theme.typography.menuCaption,
	},
	menuDivider: {
		marginTop: '2px',
		marginBottom: '10px',
	},
}))

// Define the props type
interface NavGroupProps {
	item: MenuItem
}

function NavGroup({ item }: NavGroupProps) {
	const classes = useStyles()

	// menu list collapse & items
	const items = item.children?.map((menu) => {
		switch (menu.type) {
			case 'collapse':
				return <NavCollapse key={menu.id} menu={menu} level={1} />
			case 'item':
				return <NavItem key={menu.id} item={menu} level={1} />
			default:
				return (
					<Typography key={menu.id} variant="h6" color="error" align="center">
						Menu Items Error
					</Typography>
				)
		}
	})

	return (
		<>
			<List
				subheader={
					item.title && (
						<Typography
							variant="caption"
							className={classes.menuCaption}
							display="block"
							gutterBottom
						>
							{item.title}
							{item.caption && (
								<Typography
									variant="caption"
									className={classes.subMenuCaption}
									display="block"
									gutterBottom
								>
									{item.caption}
								</Typography>
							)}
						</Typography>
					)
				}
			>
				{items}
			</List>

			{/* group divider */}
			<Divider className={classes.menuDivider} />
		</>
	)
}

export default NavGroup
