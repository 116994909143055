import { useState, useEffect } from 'react'
import axios from 'axios'
import { BASE_URL } from '../config'

export type Course = {
	id: number
	courseName: string
	courseOlId: number
}

export type ProficiencyAssessment = {
	id: number
	name: string
	passingThreshold: number
}

export type CertificateAssessment = {
	id: number
	certificateId: number
	proficiencyAssessment: ProficiencyAssessment
	proficiencyAssessmentId: number
	order: number
}

export type Certificate = {
	id: number
	certificateName: string
	certificateIssuer: string
	certificateCourses: Course[]
	minimumMasteryScore: number
	expiryPeriod: number
	certificateAssessments: CertificateAssessment[]
}

const getAuthToken = (tokenName: string): string => {
	const token = localStorage.getItem(tokenName)
	return tokenName === 'sessionToken'
		? token || 'defaultSessionTokenValue'
		: `Bearer ${token}`
}

const useCertificateDataFetcher = <T extends Certificate>(
	id: string,
	tokenName: string,
) => {
	const [certificateData, setCertificateData] = useState<T | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)

	useEffect(() => {
		const fetchCertificateData = async () => {
			setIsLoading(true)
			setError(null)
			try {
				const authorizationToken = getAuthToken(tokenName)
				const response = await axios.get<T>(`${BASE_URL}/certificates/${id}`, {
					headers: { Authorization: authorizationToken },
				})
				setCertificateData(response.data)
			} catch (err) {
				setError(err instanceof Error ? err.message : String(err))
			} finally {
				setIsLoading(false)
			}
		}

		if (id) {
			fetchCertificateData()
		}
	}, [id, tokenName])

	return { certificateData, isLoading, error }
}

export default useCertificateDataFetcher
