import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles, useTheme } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import {
	Avatar,
	Button,
	Card,
	CardContent,
	Chip,
	ClickAwayListener,
	Divider,
	Grid,
	InputAdornment,
	List,
	ListItemIcon,
	ListItemText,
	OutlinedInput,
	Paper,
	Popper,
	Typography,
} from '@material-ui/core'
import ListItemButton from '@material-ui/core/ListItemButton'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { IconLogout, IconSearch, IconSettings } from '@tabler/icons'
import MainCard from '../../../../ui-component/cards/MainCard'
import Transitions from '../../../../ui-component/extended/Transitions'

import { logout } from '../../../../store/slices/accountSlice'

const useStyles = makeStyles((theme) => ({
	navContainer: {
		width: '100%',
		maxWidth: '350px',
		minWidth: '300px',
		backgroundColor: theme.palette.background.paper,
		borderRadius: '10px',
		[theme.breakpoints.down('sm')]: {
			minWidth: '100%',
		},
	},
	headerAvatar: {
		cursor: 'pointer',
		...theme.typography.mediumAvatar,
		margin: '8px 0 8px 8px !important',
	},
	profileChip: {
		height: '48px',
		alignItems: 'center',
		borderRadius: '27px',
		transition: 'all .2s ease-in-out',
		borderColor: theme.palette.primary.light,
		backgroundColor: theme.palette.primary.light,
		'&[aria-controls="menu-list-grow"], &:hover': {
			borderColor: theme.palette.primary.main,
			background: `${theme.palette.primary.main}!important`,
			color: theme.palette.primary.light,
			'& svg': {
				stroke: theme.palette.primary.light,
			},
		},
	},
	profileLabel: {
		lineHeight: 0,
		padding: '12px',
	},
	listItem: {
		marginTop: '5px',
	},
	cardContent: {
		padding: '16px !important',
	},
	card: {
		backgroundColor: theme.palette.primary.light,
		marginBottom: '16px',
		marginTop: '16px',
	},
	searchControl: {
		width: '100%',
		paddingRight: '8px',
		paddingLeft: '16px',
		marginBottom: '16px',
		marginTop: '16px',
	},
	startAdornment: {
		fontSize: '1rem',
		color: theme.palette.grey[500],
	},
	flex: {
		display: 'flex',
	},
	name: {
		marginLeft: '2px',
		fontWeight: 400,
	},
	ScrollHeight: {
		height: '100%',
		maxHeight: 'calc(100vh - 250px)',
		overflowX: 'hidden',
	},
	badgeWarning: {
		backgroundColor: theme.palette.warning.dark,
		color: '#fff',
	},
}))

function ProfileSection() {
	const classes = useStyles()
	const theme = useTheme()
	const customization = useSelector((state) => state.customization)

	const dispatch = useDispatch()
	const history = useHistory()

	const [value, setValue] = useState('')

	const [selectedIndex] = useState(1)

	const [open, setOpen] = useState(false)
	const anchorRef = useRef(null)

	const userInfo = JSON.parse(localStorage.getItem('userData'))

	const firstName = userInfo?.givenName?.substring(0, 1).toUpperCase()
	const lastName = userInfo?.surname?.substring(0, 1).toUpperCase()

	const greetFunction = () => {
		const hour = new Date().getHours()
		if (hour < 12) {
			return 'Good Morning'
		}
		if (hour < 18) {
			return 'Good Afternoon'
		}
		return 'Good Evening'
	}

	const handleLogoutClick = () => {
		try {
			dispatch(logout())
			localStorage.clear()
			history.push('/login')
		} catch (error) {
			console.error('Failed to navigate:', error)
			window.location.href = '/login'
		}
	}

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}

		setOpen(false)
	}
	const prevOpen = useRef(open)
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus()
		}

		prevOpen.current = open
	}, [open])
	return (
		<>
			<Chip
				classes={{ label: classes.profileLabel }}
				className={classes.profileChip}
				icon={
					<Avatar
						className={classes.headerAvatar}
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						color="#fff"
						style={{ backgroundColor: '#b29962', color: 'white' }}
						sx={{ fontSize: '1rem' }}
					>
						{firstName}
						{lastName}
					</Avatar>
				}
				label={
					<IconSettings
						stroke={1.5}
						size="1.5rem"
						color={theme.palette.primary.main}
					/>
				}
				variant="outlined"
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				color="primary"
			/>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 14],
							},
						},
					],
				}}
			>
				{({ TransitionProps }) => (
					<Transitions in={open} {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MainCard
									border={false}
									elevation={16}
									content={false}
									boxShadow
									shadow={theme.shadows[16]}
								>
									<CardContent className={classes.cardContent}>
										<Grid container direction="column" spacing={0}>
											<Grid item className={classes.flex}>
												<Typography
													component="span"
													style={{ marginRight: '5px' }}
													variant="h4"
												>
													{greetFunction()}
												</Typography>
												<Typography variant="h4" className={classes.name}>
													{userInfo.givenName} {userInfo.surname}
												</Typography>
											</Grid>
										</Grid>
										<OutlinedInput
											className={classes.searchControl}
											id="input-search-profile"
											value={value}
											onChange={(e) => setValue(e.target.value)}
											placeholder="Search profile options"
											startAdornment={
												<InputAdornment position="start">
													<IconSearch
														stroke={1.5}
														size="1.3rem"
														className={classes.startAdornment}
													/>
												</InputAdornment>
											}
											aria-describedby="search-helper-text"
											inputProps={{
												'aria-label': 'weight',
											}}
										/>
										<Divider />
										<PerfectScrollbar className={classes.ScrollHeight}>
											<Card style={{ backgroundColor: '#b29962' }}>
												<CardContent>
													<Grid container spacing={3} direction="column">
														<Grid item>
															<Grid
																item
																container
																alignItems="center"
																justifyContent="space-between"
															>
																<Grid item>
																	<Button
																		onClick={() => history.push('/profile')}
																		style={{ color: '#FFF' }}
																		variant="text"
																	>
																		Profile
																	</Button>
																</Grid>
															</Grid>
														</Grid>
														<Grid item>
															<Grid
																item
																container
																alignItems="center"
																justifyContent="space-between"
															>
																<Grid item>
																	<Button
																		onClick={() => history.push('/dashboard')}
																		style={{ color: '#FFF' }}
																		variant="text"
																	>
																		Dashboard
																	</Button>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</CardContent>
											</Card>
											<List component="nav" className={classes.navContainer}>
												<ListItemButton
													className={classes.listItem}
													sx={{
														borderRadius: `${customization.borderRadius}px`,
													}}
													selected={selectedIndex === 4}
													onClick={handleLogoutClick}
												>
													<ListItemIcon>
														<IconLogout stroke={1.5} size="1.3rem" />
													</ListItemIcon>
													<ListItemText
														primary={
															<Typography variant="body2">Logout</Typography>
														}
													/>
												</ListItemButton>
											</List>
										</PerfectScrollbar>
									</CardContent>
								</MainCard>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</>
	)
}

export default ProfileSection
